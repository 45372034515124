import * as yup from 'yup'

const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{9}$/

export const authSchema = yup
  .object({
    phone: yup
      .string()
      .trim()
      .matches(phoneRegExp, 'Укажите телефон')
      .required('Обязательно к заполнению'),
    password: yup.string().required('Обязательно к заполнению'),
  })
  .required()
