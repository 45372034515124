import React from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import { Box } from '@mui/material'
import { Button } from './button'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '1px solid rgba(18, 18, 18, 0.05)',
  boxShadow: '0px 18px 52.8537px rgba(215, 228, 249, 0.1)',
  borderRadius: '18px',
  p: 4,
}

type Props = {
  open: boolean
  handleClose: () => void
}

export const ModalErrors = ({ open, handleClose }: Props) => {
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className={'custom-modal'}>
        <Box sx={style}>
          <div
            className={'request-tab__block-not-card'}
            style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
          >
            <div className={'request-tab__block-not-card__text'}>
              <div
                className={'request-tab__block-not-card__text__title'}
                style={{ textAlign: 'left', justifyContent: 'flex-start' }}
              >
                <img src={`/assets/icons/bad.svg`} /> Произошла ошибка!
              </div>
              <div
                className={'request-tab__block-not-card__text__text'}
                style={{ textAlign: 'left' }}
              >
                Во время работы сайта произошла ошибка.
                <br /> Попробуйте повторить. С уважением Монетка.
              </div>
            </div>
            <div
              className={'request-tab__block-not-card__buttons'}
              style={{
                paddingBottom: 0,
              }}
            >
              <Button
                theme={'gray'}
                title={'Продолжить'}
                onClick={handleClose}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}
