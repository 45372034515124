import React from 'react'

type Props = {
  title: string
  link: string
}

export const Document = (props: Props) => {
  return (
    <div className={'document'}>
      <div className={'document__title'}>
        <img src={'/assets/document.svg'} />
        {props.title}
      </div>
      <div className={'document__open-link'}>
        <a rel="noreferrer" target={'_blank'} download href={props.link}>
          Открыть PDF <img src={'/assets/arrow.svg'} />
        </a>
      </div>
    </div>
  )
}
