import classNames from 'classnames'
import React from 'react'

type Props = {
  onClick?: () => void
  title: string
  className?: string
  style?: any
  theme?: 'dark' | 'white' | 'yellow' | 'gray'
  iconButton?: JSX.Element
  disabled?: boolean
}

export const Button = (props: Props) => {
  return (
    <button
      {...props}
      className={classNames(props.className, {
        'button__dark-theme': props.theme === 'dark',
        'button__white-theme': props.theme === 'white',
        'button__yellow-theme': props.theme === 'yellow',
        'button__gray-theme': props.theme === 'gray',
      })}
    >
      {props.iconButton && props.iconButton}
      {props.title || 'Сохранить'}
    </button>
  )
}
