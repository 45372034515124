import React from 'react'
import { CardAbout } from './card-about'
import './style.scss'
import { Button } from '../../../../components/button'
import { About } from '../type'
import Slider from 'react-slick'
import { useNavigate } from 'react-router'

const AboutHome = (props: { data: About; title?: string }) => {
  const { title = 'Как получить займ?' } = props
  const navigate = useNavigate()

  const settings = {
    className: 'center slider variable-width',
    centerMode: true,
    // centerPadding: '30px',
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    adaptiveHeight: true,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    autoplay: true,
  }
  return (
    <React.Fragment>
      <div className={'container'}>
        <script async src="https://network.adsfin.pro/59918a10.js"></script>
        <div className={'container__row'}>
          <div className={'home__about'}>
            <img
              className={'home__about__img'}
              src={'/assets/background/circle.svg'}
            />
            <div className={'home__about__title'}>
              <h1 className={'text-title'}>{title}</h1>
            </div>
            <div className={'home__about__cards'}>
              {props.data.map(item => (
                <CardAbout
                  key={item.id}
                  img={<img src={item.img} />}
                  text={item.text}
                  title={item.title}
                />
              ))}
            </div>
            <div className={'home__about__button'}>
              <Button
                theme={'yellow'}
                title={'Оформить заявку'}
                onClick={() => navigate('/register')}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'inline' }}>
        <div className={'slider-about'}>
          <Slider {...settings} className={'custom-slider'}>
            {props.data.map(item => (
              <CardAbout
                key={item.id}
                img={<img src={item.img} />}
                text={item.text}
                title={item.title}
              />
            ))}
          </Slider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutHome
