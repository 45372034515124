import React from 'react'
import './style.scss'

type Props = {
  img: JSX.Element
  title: string
  text: string
}

export const CardAbout = (props: Props) => {
  return (
    <div className={'container container-card'}>
      <div className={'container__row'}>
        <div className={'card-about'}>
          <span className={'card-about__image'}>{props.img}</span>
          <p className={'card-about__title'}>{props.title}</p>
          <span className={'card-about__text'}>{props.text}</span>
        </div>
      </div>
    </div>
  )
}
