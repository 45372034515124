export function useConvertDate(value: number) {
  const units = ['Дней', 'Месяц', 'Месяца', 'Месяцев']
  let unitIndex = 0
  let scaledValue = value
  const wholeNumber = value / 31

  if (wholeNumber > 1) {
    if (wholeNumber < 5) {
      unitIndex = 2
      scaledValue = wholeNumber
    } else {
      unitIndex = 3
      scaledValue = wholeNumber
    }
  }

  return { value: Math.ceil(scaledValue), textDate: units[unitIndex] }
}
