import { FormHelperText, MenuItem, OutlinedInput, Select } from '@mui/material'
import React from 'react'

const SelectDay = ({
  field,
  onChange,
  errorText,
  value,
  label,
  options,
}: any) => {
  const [day, setDay] = React.useState<String | Number>('')
  const [month, setMonth] = React.useState<String | Number>('')
  const [year, setYear] = React.useState<String | Number>('')

  React.useEffect(() => {
    if (value) {
      setMonth(new Date(value).getMonth() + 1)
      setYear(new Date(value).getFullYear())
      setDay(new Date(value).getDate())
    }
  }, [])

  React.useEffect(() => {
    if (
      Number.isFinite(day) &&
      Number.isFinite(month) &&
      Number.isFinite(year)
    ) {
      onChange(new Date(year as number, month as number, day as number))
    }
  }, [day, month, year])

  return (
    <div>
      <label className={'personal-data__label'}>
        {label || 'Дата рождения'}
      </label>
      <div className={'select-data'}>
        <div className={'select-data__day'} style={{ width: '100%' }}>
          <Select
            {...field}
            style={{ width: '100%' }}
            placeholder={'День'}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value={day}
            onChange={value => setDay(value.target.value as any)}
          >
            <MenuItem disabled value="">
              <em>День</em>
            </MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={13}>13</MenuItem>
            <MenuItem value={14}>14</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={17}>17</MenuItem>
            <MenuItem value={18}>18</MenuItem>
            <MenuItem value={19}>19</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={21}>21</MenuItem>
            <MenuItem value={22}>22</MenuItem>
            <MenuItem value={23}>23</MenuItem>
            <MenuItem value={24}>24</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={26}>26</MenuItem>
            <MenuItem value={27}>27</MenuItem>
            <MenuItem value={28}>28</MenuItem>
            <MenuItem value={29}>29</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={31}>31</MenuItem>
          </Select>
        </div>
        <div className={'select-data__month'}>
          <label className={'personal-data__label'}> </label>
          <Select
            {...field}
            style={{ width: '100%' }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value={month}
            onChange={value => setMonth(value.target.value as number)}
          >
            <MenuItem disabled value="">
              <em>Месяц</em>
            </MenuItem>
            <MenuItem value={1}>Январь</MenuItem>
            <MenuItem value={2}>Февраль</MenuItem>
            <MenuItem value={3}>Март</MenuItem>
            <MenuItem value={4}>Апрель</MenuItem>
            <MenuItem value={5}>Май</MenuItem>
            <MenuItem value={6}>Июнь</MenuItem>
            <MenuItem value={7}>Июль</MenuItem>
            <MenuItem value={8}>Август</MenuItem>
            <MenuItem value={9}>Сентябрь</MenuItem>
            <MenuItem value={10}>Октябрь</MenuItem>
            <MenuItem value={11}>Ноябрь</MenuItem>
            <MenuItem value={12}>Декабрь</MenuItem>
          </Select>
        </div>
        <div className={'select-data__years'}>
          <label className={'personal-data__label'}> </label>
          <Select
            {...field}
            style={{ width: '100%' }}
            placeholder={'День'}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value={year}
            onChange={value => setYear(value.target.value as any)}
          >
            <MenuItem disabled value="">
              <em>Год</em>
            </MenuItem>
            {options.map((item: number) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <label className={'text-errors'}>{errorText || ''}</label>
    </div>
  )
}
export default SelectDay
