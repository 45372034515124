import React, { useCallback } from 'react'
import env from '../../env'

type Props = { url: string; method?: string; params?: any }

export const useRequestDaData = ({ url, method = 'GET', params }: Props) => {
  const [data, setData] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [errors, setError] = React.useState(null)

  React.useEffect(() => {
    search(url, params, method)
  }, [])

  const clearData = useCallback(() => {
    setData(null)
  }, [])

  const search = useCallback(
    (url: string, params: any, method: string) => {
      setLoader(true)
      setError(null)
      fetch(env.suggestionsUrlDadata + url, {
        method,
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          Authorization: env.tokenDadata,
          Accept: 'application/json',
        },
      })
        .then(res => res.json())
        .then(
          result => setData(result),
          error => setError(error)
        )
        .finally(() => setLoader(false))
    },
    [url]
  )

  return { data, search, loader, clearData, errors }
}
