import { About, About as Convenience } from './type'

export const about: About = [
  {
    id: 0,
    img: '/assets/about/questionnaire.svg',
    text: `Вносите только достоверные данные, как указано в паспорте`,
    title: 'Заполните анкету',
  },
  {
    id: 1,
    img: '/assets/about/watch.svg',
    text: `Решение по Вашей заявке занимает не более 10 минут`,
    title: 'Дождитесь одобрения',
  },
  {
    id: 2,
    img: '/assets/about/money.svg',
    text: `После проверки заявки, Вы получите деньги удобным для Вас способом`,
    title: 'Получите деньги',
  },
]

export const convenience: Convenience = [
  {
    id: 0,
    img: '/assets/convenience/around.svg',
    text: `В период праздников, выходных дней и ночное время большинство банков не работает. Но мы поможем найти кредитора, который выдаст заём Вам прямо сейчас.`,
    title: 'Работаем круглосуточно',
  },
  {
    id: 1,
    img: '/assets/convenience/approval.svg',
    text: `Если вы испортили кредитную историю и банки отказывают вам, то мы найдем банк, который будет готов выдать заём , даже с вашими текущими просрочками.`,
    title: 'Высокий процент одобрения',
  },
  {
    id: 2,
    img: '/assets/convenience/protection.svg',
    text: `На нашем сервисе используются современные технологии, что помогает обеспечить высокий уровень защиты персональных данных. Для нас важна Ваша безопасность.`,
    title: 'Ваши данные защищены',
  },
]

export const questionsAnswers = [
  {
    id: 1,
    title: 'Как получить деньги?',
    text: 'Просто заполните анкету, выберите сумму, срок займа и способ получения денег, удобный для вас.',
  },
  {
    id: 2,
    title: 'Какие требования к заемщикам?',
    text: 'Займ предоставляет клиентам от 18 до 90 лет. Обязательно иметь гражданство РФ и банковскую карту. Процентная ставка составляет от от 0% до 1% в день, сумма первого займа варьируется от 1 000 до 100 000 рублей. Последующие займы ограничены лимитом в 30 000 рублей. Срок кредитования: от 5 до 30 дней.',
  },
  {
    id: 3,
    title: 'Как быстро деньги будут зачислены на карту?',
    text: 'Первый займ требует регистрации клиента, которая занимает около 15 минут. Если заявка одобрена, деньги будут перечислены заемщику в течение 2 минут. Начиная со второго займа, моментально.',
  },
  {
    id: 4,
    title: 'Как погасить займ?',
    text: 'Закрыть заем можно как досрочно, так и своевременно. Сделать это можно через личный кабинет, оплатив задолженность банковской картой; банковским переводом; наличными, посетив офис компании.',
  },
]
export const subscription = [
  {
    id: 0,
    img: '/assets/subscription/1.png',
    text: `Все услуги собраны у Вас в личном кабинете где вы можете ими управлять.`,
    title: 'Доступ в личный кабинет',
  },
  {
    id: 1,
    img: '/assets/subscription/2.png',
    text: `Рейтинг финансового здоровье - это один из главных показателей, который оценивается банками при решении о предоставлении займа. Он неразрывно связан с кредитной историей и, фактически, представляет собой числовую оценку ее качества`,
    title: 'Расчет кредитного рейтинга',
  },
  {
    id: 2,
    img: '/assets/subscription/3.png',
    text: `Узнайте о своих долгах через федеральную службу судебных приставов
    Задолженности
    Сроки документов
    Исполнительные произвоства`,
    title: 'Проверка по ФССП',
  },
  {
    id: 3,
    img: '/assets/subscription/4.png',
    text: `Мы отправляем вашу анкету по средствам каналов API более чем в 20 финансовых учреждений, благодаря чему экономим ваше время и увеличиваем шанс получиня займа в разы!`,
    title: 'Отправка анкет по API в МФО с индивидуальными условиями',
  },
  {
    id: 0,
    img: '/assets/subscription/5.png',
    text: `С помощью push-уведомлений мы будем держать вас в курсе о проходящих акциях МФО`,
    title: 'Push-уведомления с обновлениями и акциями',
  },
  {
    id: 0,
    img: '/assets/subscription/6.png',
    text: `Мы подобрали для вас лучшие предложения по займам на персональных условиях!`,
    title: 'Витрина с предложенями от МФО которая доступна 24/7',
  },
]
